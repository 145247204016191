import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Wynagrodzenie"
        keywords={[`borkowska`, `adwokat`, `gdańsk`, `gdansk`, `kancelaria`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          {/* <figure className="kg-card kg-image-card kg-width-full">
            <Img
              fluid={data.picture.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>adw. Agnieszka Borkowska</figcaption>
          </figure> */}
          <h3 id="wynagrodzenie">Wynagrodzenie</h3>
          <p>
            Wynagrodzenie ustalane jest indywidualnie w&nbsp;zależności od
            stopnia skomplikowania sprawy oraz koniecznego nakładu pracy.
            Kancelaria stosuje następujące metody rozliczeń z&nbsp;Klientami:
          </p>
          <ul>
            <li>stawki godzinowe, </li>
            <li>stawki ryczałtowe.</li>
          </ul>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    picture: file(relativePath: { eq: "DOR_9750popr.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const RemunerationPage = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
export default RemunerationPage
